<template>
	<div>
		<van-nav-bar
			title="個人中心"
			left-arrow
			fixed
			class="qjc-nav-bar"
			@click-left="$router.back()"
		/>
		<div class="user">
			<div class="head">
				<van-image
					width="1.24rem"
					height="1.24rem"
					round
					:src="$store.state.isLogin?require('@/assets/images/touxiang.png'):require('@/assets/images/weidenglu--touxiang.png')"
				/>
				<div v-if="$store.state.isLogin">
					<!-- <div class="name qjc-fts-32 qjc-c-dark qjc-ftw-b">{{ name }}</div> -->
					<p class="phone">當前手機號：{{ mobileHide }}</p>
				</div>
				<div class="nologin" v-else>
					<!-- <van-button to="/regist" class="qjc-c-primary qjc-borderc-primary qjc-fts-28 qjc-ftw-b">註冊</van-button> -->
					<van-button to="/login" class="qjc-c-primary qjc-borderc-primary qjc-fts-28 qjc-ftw-b">登錄</van-button>
				</div>
			</div>
			<div class="list qjc-texta-l qjc-fts-32">
				
				<van-cell center title="歷史記錄" is-link to="/user/history">
					<van-image
						slot="icon"
						width="0.32rem"
						height="0.33rem"
						:src="require('@/assets/images/lishijilu.png')"
					/>
				</van-cell>
				
			</div>
			<!-- <van-button v-if="$store.state.isLogin" @click="exit" class="exit qjc-c-primary qjc-ftw-b qjc-borderc-primary qjc-fts-32">退出登錄</van-button> -->
		</div>
		
		<!-- 底部导航 -->
		<van-tabbar 
			v-model="navIcon.active"
			active-color="#6681FA"
			inactive-color="#9DA6B9"
			safe-area-inset-bottom
		>
			<van-tabbar-item name="home" to="/aizh">
				<span>首頁</span>
				<img 
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.home.active:navIcon.home.normal"
				>
			</van-tabbar-item>
			<van-tabbar-item name="user" to="/aizh/user">
				<span>我的</span>
				<img
					slot="icon"
					slot-scope="props"
					:src="props.active?navIcon.user.active:navIcon.user.normal"
				>
			</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Dialog);
	
	export default{
		name: 'user',
		data (){
			return {
				name: '',//姓名
				mobile: '',
				navIcon: {
					active: 'user',
					home: {
						normal: require('@/assets/images/shouye1.png'),
						active: require('@/assets/images/shouye.png')
					},
					user: {
						normal: require('@/assets/images/wode.png'),
						active: require('@/assets/images/wode2.png')
					}
				},
				
				complete: false,//问卷是否完成
				
				yjqz: false// 未完成报告是否为疫境求真
			}
		},
		computed: {
			mobileHide (){
				var start,end;
				if(this.mobile){
					if(this.mobile.indexOf('@') == -1){
						start = 3,
						end = 3;
						return this.mobile.slice(0,start) + this.hideStr(this.mobile.length-start-end) + this.mobile.slice(-end);
					}else{
						start = 2,
						end = 2;
						var emailArr = this.mobile.split('@');
						return this.mobile.slice(0,start) + this.hideStr(this.mobile.length-start-end-emailArr[1].length) + emailArr[0].slice(-end) + '@' + emailArr[1];
					}
				}else{
					return this.mobile;
				}
				
			}
		},
		mounted() {
			// 已登录存在未完成问卷提示
			if(this.$store.state.isLogin){
				// isPay().then(res => {
				// 	// console.log(res);
				// 	if(res.code == 200){
						
				// 	}
				// })
			}
			
			var user = JSON.parse(localStorage.getItem('user'));
			if(this.$store.state.isLogin && user){
				this.name = user.name;
				this.mobile = user.mobile;
			}
			
		  document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
		  document.querySelector('body').removeAttribute('style')
		},
		methods: {
			//退出登录
			exit (){
				this.$store.dispatch('exit').then(() => {
					Toast.success('已退出');
				});
			},
			hideStr (length){
				var str = '';
				for(var i=0;i<length;i++){
					str += '*';
				}
				return str;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.user{
		margin-top: 0.88rem;
		margin-bottom: 0.98rem;
		padding: 0.32rem 0;
		background: url(../../assets/images/bg1.png) no-repeat top center;
		background-size: 100% 2.5rem;
		
		.head{
			margin: 0 0.24rem 0.24rem;
			height: 3.16rem;
			padding: 0.36rem 0 0.6rem;
			border-radius: 0.08rem;
			background: #fff url(../../assets/images/bg2.png) no-repeat bottom center;
			background-size: 100% 0.7rem;
			
			.van-image{
				box-shadow:0 0.05rem 0.1rem 0 rgba(203,208,217,1);
			}
			.name{
				margin: 0.1rem 0 0.02rem;
			}
			.phone{
				color: #A1A7B2;
			}
			.nologin{
				margin-top: 0.3rem;
				
				.van-button{
					width: 1.12rem;
					height: 0.56rem;
					line-height: 0.56rem;
					padding: 0 0.2rem;
					background-color: transparent;
					// margin-right: 0.24rem;
					border-radius: 0.04rem;
				}
			}
		}
		.list{
			&>.van-cell{
				margin-top: 0.24rem;
			}
			.van-cell{
				height: 1.28rem;
				font-size: 0.32rem;
				font-weight: 500;
				
				&:not(:last-child)::after{
					border-bottom: 1px solid #DCDEE5;
					left: 0.32rem;
					right: 0.32rem;
				}
				.van-image{
					margin-right: 0.17rem;
				}
			}
		}
		.exit{
			width: 6.3rem;
			height: 0.78rem;
			line-height: 0.78rem;
			background: transparent;
			border-radius: 0.08rem;
			margin-top: 0.65rem;
		}
	}
	.van-tabbar{
		height: 0.98rem;
		
		span{
			font-size: 0.2rem;
		}
		img{
			width: 0.43rem;
			height: 0.44rem;
		}
	}

	// 未完成提示
	.assess-nodone{
		width: 6.3rem;
		padding: 0.56rem;
		border-radius: 0.16rem;
		overflow-y: visible;
		
		p{
			line-height: 0.52rem;
			margin: 0.3rem 0;
		}
		.van-button{
			width: 2.5rem;
			line-height: 0.76rem;
			margin: 0 0.04rem;
		}
		.close{
			width: 0.72rem;
			height: 0.72rem;
			padding-top: 0.22rem;
			left: 50%;
			bottom: -1.72rem;
			margin-left: -0.36rem;
			border: 0.01rem solid #fff;
		}
		
		&.yjqz-alert{
			width: 6.5rem;
			height: 10.14rem;
			padding: 0;
			background-color: transparent;
			
			.yjqz-box{
				.van-button{
					position: absolute;
					left: 4%;
					bottom: 0.48rem;
					width: 91%;
					height: 0.74rem;
					border-radius: 0.08rem;
				}
			}
		}
	}
	
	
</style>
